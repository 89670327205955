import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroSection } from "../components/hero-section"
import { IconListSection } from "../components/icon-list-section"
import { UsInNumbersSection } from "../components/us-in-numbers-section"
import { AboutBHSection } from "../components/about-bh-section"
import { BannerSection } from "../components/banner-section"
import { BhExperienceSection } from "../components/bh-experience-section"
import { WorkingAtBhSection } from "../components/working-at-bh-section"
import { processHexagonData } from "../helpers/common"

const HomeTemplate = ({
  data: {
    wpgraphql: {
      page: { title, homepageacf },
    },
  },
}) => {
  const hexagonData = processHexagonData([
    homepageacf.usInNumbers.name,
    homepageacf.aboutBrevan.name,
    homepageacf.atAGlanceSectionName,
    homepageacf.ourCulture.name,
    homepageacf.careersSection.name,
    homepageacf.banner.name,
  ])

  return (
    <Layout preloader={true}>
      <SEO
        title={title}
        description={
          "Brevan Howard is a top global investment platform, specializing in macro strategies for institutional and private investors worldwide."
        }
        canonical={"https://www.brevanhoward.com"}
      />

      <HeroSection
        heroTitle={homepageacf.heroTitle}
        heroImage={homepageacf.heroSectionImage}
      />

      <UsInNumbersSection
        title={homepageacf.usInNumbers.title}
        text={homepageacf.usInNumbers.text}
        hexagonData={hexagonData[0]}
        yearsNumber={homepageacf.usInNumbers.yearsNumber}
        yearsTitle={homepageacf.usInNumbers.yearsTitle}
        yearsText={homepageacf.usInNumbers.yearsText}
        yearsSymbol={homepageacf.usInNumbers.yearsSymbol}
        items={homepageacf.usInNumbers.numbersList}
        footnote={homepageacf.usInNumbers.listFootnote}
        linkData={homepageacf.usInNumbers.acfLink}
      />

      <AboutBHSection
        hexagonData={hexagonData[1]}
        title={homepageacf.aboutBrevan.title}
        text={homepageacf.aboutBrevan.text}
        linkData={homepageacf.aboutBrevan.acfLink}
        image={homepageacf.aboutBrevan.image}
      />

      <IconListSection
        hexagonData={hexagonData[2]}
        title={homepageacf.atAGlanceTitle}
        list={homepageacf.atAGlanceItems}
        cutouts="cutout-top"
      />

      <BhExperienceSection
        hexagonData={hexagonData[3]}
        title={homepageacf.ourCulture.title}
        text1={homepageacf.ourCulture.leftTextColumn}
        text2={homepageacf.ourCulture.rightTextColumn}
        linkData={homepageacf.ourCulture.acfLink}
        image1={homepageacf.ourCulture.image2}
        image2={homepageacf.ourCulture.image1}
      />

      <WorkingAtBhSection
        hexagonData={hexagonData[4]}
        title={homepageacf.careersSection.title}
        text={homepageacf.careersSection.text}
        linkData={homepageacf.careersSection.acfLink}
        image1={homepageacf.careersSection.image2}
        image2={homepageacf.careersSection.image1}
      />

      <BannerSection
        hexagonData={hexagonData[5]}
        title={homepageacf.banner.title}
        linkData={homepageacf.banner.acfLink}
        background={homepageacf.banner.image}
        backgroundMobile={homepageacf.banner.imageMobile}
      />
    </Layout>
  )
}

export default HomeTemplate

export const homeTemplateQuery = graphql`
  query MdPostQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        homepageacf {
          heroTitle
          heroSectionImage {
            mediaItemUrl
            mediaItemId
            modified
            localImageFile {
              childImageSharp {
                original {
                  src
                  width
                }
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }

          atAGlanceSectionName
          atAGlanceTitle
          atAGlanceItems {
            itemIcon
            title
          }
          usInNumbers {
            listFootnote
            name
            text
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
            numbersList {
              number
              symbol
              text
              title
            }
            yearsNumber
            title
            yearsText
            yearsTitle
            yearsSymbol
          }

          aboutBrevan {
            name
            title
            text
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
          }

          ourCulture {
            title
            rightTextColumn
            leftTextColumn
            name
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
            image1 {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            image2 {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }

          careersSection {
            name
            title
            text
            image1 {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            image2 {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
          }

          banner {
            name
            title
            image {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageMobile {
              mediaItemUrl
              mediaItemId
              modified
              localImageFile {
                childImageSharp {
                  original {
                    src
                    width
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            acfLink {
              externalUrl
              fieldGroupName
              linkText
              type
              openInNewWindow
              linkToPage {
                ... on WPGraphQL_Page {
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`
